import styled from 'styled-components'

export const ContainerOurTeam = styled.section`
    .bannerOutTeam {
        height: 350px;
        width: 100%;
        background-image: ${(props) => `url(${props.backgroundImage})`};
        background-position: center;
        background-repeat: no-repeat;
        background-size: auto;

        display: flex;
        flex-direction: column;
        text-align: center;
        justify-content: flex-end;
    }

    .wrapperEmployeeOurTeam {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-around;

        .itemEmployeeOurTeam {
            flex-basis: 30%;
        }

        @media only screen and (max-width: 350px) {
            .itemEmployeeOurTeam {
                flex-basis: 80% !important;
            }
        }

        @media only screen and (max-width: 798px) {
            .itemEmployeeOurTeam {
                flex-basis: 45%;
            }
        }
    }
`
