import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import ReactMarkdown from 'react-markdown'

import AboutLayout from '../../components/Layout/About'

import { ContainerOurTeam } from '../../styles/ourTeam'
import { CardEmployee } from '../../components/cards'

const OurTeamPage = () => {
    const { employeers: employers, imgDefault, ourTeam } = useStaticQuery(graphql`
        query {
            employeers: allStrapiFuncionarios(sort: { fields: name, order: ASC }) {
                nodes {
                    id
                    strapiId
                    name
                    office
                    linkedin_link
                    phoneNumber
                    email_link
                    photo {
                        childImageSharp {
                            fixed(width: 160, quality: 100) {
                                ...GatsbyImageSharpFixed
                            }
                        }
                    }
                }
            }

            imgDefault: allFile(filter: { relativePath: { eq: "default/defaultPerson.png" } }) {
                nodes {
                    childImageSharp {
                        fixed(width: 160, quality: 100) {
                            ...GatsbyImageSharpFixed
                        }
                    }
                }
            }

            ourTeam: allStrapiSobre {
                nodes {
                    id
                    staff
                    staff_image {
                        publicURL
                    }
                }
            }
        }
    `)

    return (
        <AboutLayout type="our-team">
            <ContainerOurTeam backgroundImage={ourTeam.nodes[0].staff_image.publicURL}>
                <div className="backgroundContentAbout mb-4">
                    <h1 className="font-subtitle">Nosso Time</h1>
                </div>

                <span className="bannerOutTeam" />

                <ReactMarkdown className="text-about">{ourTeam.nodes[0].staff}</ReactMarkdown>

                <div className="wrapperEmployeeOurTeam mt-3px">
                    {employers.nodes.map((employer, index) => (
                        <div className="itemEmployeeOurTeam" key={employer.strapiId}>
                            <CardEmployee
                                img={
                                    employer.photo?.childImageSharp?.fixed ??
                                    imgDefault?.nodes[0]?.childImageSharp?.fixed
                                }
                                name={employer?.name}
                                office={employer?.office}
                                linkedin={employer?.linkedin_link}
                                phoneNumber={employer?.phoneNumber}
                                email={employer?.email_link}
                            />
                        </div>
                    ))}
                </div>
            </ContainerOurTeam>
        </AboutLayout>
    )
}

export default OurTeamPage
